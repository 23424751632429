import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import parse, { domToReact } from 'html-react-parser';
import classNames from 'classnames';
import TranslationService from 'mfe-articles-shared/services/TranslationService/TranslationService';
import DataLayer from 'mfe-articles-shared/domains/DataLayer/DataLayer';
import styles from './Summary.module.scss';

const VIEWPORTS = {
    'x-small': 600,
    small: 768,
    large: 1025,
    'x-large': 1156,
};

const ANALYTICS_SELECTORS = {
    EXPAND_SUMMARY: 'expand-exec-summary-link',
};

const options = {
    replace: ({ type: typeOption, name, children }) => {
        if (typeOption === 'tag' && name === 'p') {
            return (
                <span className={styles['summary-text']}>
                    {domToReact(children)}
                </span>
            );
        }
        if (typeOption === 'tag' && name.includes('h')) {
            return (
                <h6 className={styles['summary-title']}>
                    {domToReact(children)}
                </h6>
            );
        }

        return {};
    },
};

function Summary() {
    const { summary, title, issue, type } = useArticleStore(
        (state) => state.article,
    );
    const articleType = type.replace(/\s+/g, '-');
    const { query } = useRouter();
    const completeSummary = summary ? parse(summary, options) : '';
    const contentType = issue ? 'Magazine Article' : 'Digital Article';
    const ts = new TranslationService(query?.language);

    const [croppedSummary, setCroppedSummary] = useState('');
    const [cropComplete, setCropComplete] = useState(false);
    const [isSummaryCollapsed, setIsSummaryCollapsed] = useState(true);
    const [buttonText, setButtonText] = useState(ts.strings.more);
    const [updateHeight, setUpdateHeight] = useState(false);

    const getLimitCharacters = () => {
        let limit = 200;
        if (
            window.innerWidth > VIEWPORTS['x-small'] &&
            window.innerWidth < VIEWPORTS.small
        )
            limit = 220;
        if (
            window.innerWidth >= VIEWPORTS.small &&
            window.innerWidth < VIEWPORTS.large
        )
            limit = 230;
        if (
            window.innerWidth >= VIEWPORTS.large &&
            window.innerWidth < VIEWPORTS['x-large']
        )
            limit = 245;
        if (window.innerWidth >= VIEWPORTS['x-large']) limit = 260;

        return limit;
    };

    useEffect(() => {
        const limitCharacters = getLimitCharacters();

        if (summary && summary.length > limitCharacters) {
            // Get the index of the last space character of the sliced summary.
            const lastSpaceIndex = summary
                .slice(0, limitCharacters)
                .lastIndexOf(' ');
            const shortSummary = summary.slice(0, lastSpaceIndex);
            setCroppedSummary(parse(shortSummary));
            setCropComplete(true);
        }
        setUpdateHeight(true);
    }, []);

    const toogleParagraph = () => {
        if (isSummaryCollapsed) {
            setIsSummaryCollapsed(false);
            setButtonText(ts.strings.close);
            const dataLayer = new DataLayer(window.digitalData);
            dataLayer.setViewSummaryEvent(title, contentType);
            dataLayer.updateEvent();
        } else {
            setIsSummaryCollapsed(true);
            setButtonText(ts.strings.more);
        }
    };

    const displaySummary = () => {
        if (cropComplete && isSummaryCollapsed) return croppedSummary;
        return completeSummary;
    };

    const containerClasses = classNames(styles.container, styles[articleType], {
        [styles['initial-layout']]: !updateHeight,
        [styles['final-layout']]: updateHeight,
    });

    if (!summary) return null;

    return (
        <section className={containerClasses}>
            <h2 className={styles['summary-header']}>
                {ts.strings.summary}
                .&nbsp;&nbsp;&nbsp;
            </h2>
            <span className={styles['summary-text']}>{displaySummary()}</span>
            {cropComplete && (
                <span>
                    {isSummaryCollapsed ? '...' : ''}
                    <button
                        type="button"
                        className={styles.button}
                        onClick={toogleParagraph}
                        data-analytics={ANALYTICS_SELECTORS.EXPAND_SUMMARY}
                        aria-label={
                            isSummaryCollapsed
                                ? 'more summary information'
                                : 'less summary information'
                        }
                    >
                        {buttonText}
                    </button>
                </span>
            )}
        </section>
    );
}

export default Summary;
