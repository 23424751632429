import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useUser from 'mfe-core/hooks/useUser';
import useAuth from 'mfe-core/hooks/useAuth';
import UserService from 'mfe-articles-shared/services/UserService/UserService';
import ArticleService from 'mfe-articles-shared/services/ArticleService/ArticleService';
import usePaywallStore from 'mfe-articles-shared/stores/paywall/paywall.store';
import { useShallow } from 'zustand/react/shallow';
import DataLayer from 'mfe-articles-shared/domains/DataLayer/DataLayer';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';

const usePianoEvents = () => {
    const { asPath, query } = useRouter();
    const article = useArticleStore((state) => state.article);
    const { setProfile } = useUser();
    const { setIsAuthenticated } = useAuth();
    const { setShouldOpenAuthModal, setIsPaywallEnabled, setIsPaywallLoading } =
        usePaywallStore(
            useShallow((state) => {
                return {
                    setShouldOpenAuthModal: state.setShouldOpenAuthModal,
                    setIsPaywallEnabled: state.setIsPaywallEnabled,
                    setIsPaywallLoading: state.setIsPaywallLoading,
                };
            }),
        );

    const updateArticleUserProfile = useArticleStore(
        (state) => state.updateArticleUserProfile,
    );
    const setTriggerPianoComposerCall = useArticleStore(
        (state) => state.setTriggerPianoComposerCall,
    );
    const setArticle = useArticleStore((state) => state.setArticle);

    const trigger = (eventName, detail = {}, bubbles = false) => {
        const event = new CustomEvent(eventName, { detail, bubbles });
        document.body.dispatchEvent(event);
    };

    const updatePaywallState = (isEnabled) => {
        setIsPaywallEnabled(isEnabled);
        setIsPaywallLoading(false);

        window.tp.push([
            'setCustomVariable',
            'experienceRerendered',
            !isEnabled,
        ]);
    };

    const fetchArticle = async () => {
        const { article: articleResponse } = await ArticleService.getArticle(
            asPath,
            null,
            query.language,
            {},
            query?.giftToken ? window?.location.href ?? null : null,
        );
        return articleResponse;
    };

    const getArticle = async () => {
        const articleResponse = await fetchArticle();
        setArticle(articleResponse);
        setIsPaywallLoading(true);

        // Trigger piano composer call to check for paywall
        setTriggerPianoComposerCall(true);
    };

    useEffect(() => {
        const pianoEventHandler = async ({ origin, data }) => {
            const { type, data: payload, details } = data;
            if (!type) return;
            if (window.tp.customVariables.experienceRerendered === 'true')
                return;

            // Define a case for each type of Piano event you expect to handle
            switch (type) {
                case 'article-paywall:full-content':
                case 'article-paywall:ascend-full-content':
                case 'article-paywall:full-content+rl':
                case 'video:disable-paywall':
                    setTriggerPianoComposerCall(false);
                    updatePaywallState(false);
                    break;
                case 'article-paywall:enable-paywall':
                case 'article-paywall:enable-anonymus-paywall':
                case 'piano:enable-paywall-creative':
                case 'article-paywall:enable-reading-list-paywall':
                case 'article-paywall:waiver+rl':
                case 'data-visual:enable-paywall':
                case 'article-paywall:enable-registration-wall-ascend':
                case 'video:enable-paywall':
                    setTriggerPianoComposerCall(false);
                    updatePaywallState(true);
                    if (details?.paywallType) {
                        switch (details.paywallType) {
                            case 'regwall':
                                trigger('metrics:gbbPWViewed', {
                                    wall_viewed: 'Regwall Viewed',
                                });
                                break;
                            case 'subwall':
                                trigger('metrics:gbbPWViewed', {
                                    wall_viewed: 'Subwall Viewed',
                                });
                                break;
                            default:
                                console.warn(
                                    `Unknown paywallType: ${details.paywallType}`,
                                );
                                trigger('metrics:gbbPWViewed');
                        }
                    } else {
                        trigger('metrics:gbbPWViewed');
                    }
                    break;
                case 'auth-modal':
                    setTriggerPianoComposerCall(false);
                    setShouldOpenAuthModal(true);
                    break;
                case 'get-domain':
                    document
                        .querySelector(
                            '#paywall-creative-container .tp-container-inner iframe',
                        )
                        .contentWindow.postMessage(
                            {
                                type: 'set-domain',
                                value: window.location.hostname,
                            },
                            origin,
                        );
                    break;
                case 'register':
                    try {
                        setTriggerPianoComposerCall(false);
                        const profile = await UserService.create(payload);
                        setProfile(profile);
                        setIsAuthenticated(true);
                        updateArticleUserProfile(profile);

                        const dataLayer = new DataLayer(window.digitalData);
                        dataLayer.init({}, article, profile);
                        dataLayer.updateUsers();

                        trigger('piano:registration-success', { profile });
                        trigger('metrics:registration_success', {
                            profile,
                        });
                        trigger('register:success', {
                            mode: 'Registration - Modal',
                            profile,
                        });

                        // Fetch the article content for the registered user and update state
                        getArticle();
                    } catch (error) {
                        console.error(error);
                        const iframe = document.querySelector(
                            '#paywall-creative-container .tp-container-inner iframe',
                        );
                        if (iframe) {
                            iframe.contentWindow.postMessage(
                                {
                                    type: 'register-error',
                                    responseJSON: error.data,
                                },
                                origin,
                            );
                        }
                        trigger('metrics:registration_failure');
                        trigger('piano:reg-failure');
                    }

                    break;
                default:
                    console.log('Unhandled Piano event:', type);
            }
        };

        window.addEventListener('message', pianoEventHandler);

        return () => {
            window.removeEventListener('message', pianoEventHandler);
        };
    }, []);
};

export default usePianoEvents;
