import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import useArticleStore from '../../stores/article/article.store';
import styles from './NextInSeries.module.scss';

function NextInSeries() {
    const { series, type } = useArticleStore((state) => state.article);
    const articleType = type.replace(/\s+/g, '-');

    const containerClasses = classNames(styles.container, styles[articleType]);

    const imgContainerClasses = classNames(
        styles['image-container'],
        styles[articleType],
    );

    const seriesTitleClasses = classNames(styles.link, styles['series-title']);

    const nextArticleIndex = (series.currentIndex + 1) % series.articles.length;
    const nextArticle = series.articles[nextArticleIndex];

    return (
        <aside
            className={containerClasses}
            aria-label="Next article in the series"
            data-next-in="true"
        >
            <div className={styles['header-container']}>
                <div className={styles.subheader}>
                    <a href={nextArticle.url} className={styles.link}>
                        Next In
                    </a>
                </div>
                <div className={styles.header}>
                    <a href={nextArticle.url} className={seriesTitleClasses}>
                        {parse(series.title)}
                    </a>
                </div>
            </div>
            <div className={styles.content}>
                <div className={imgContainerClasses}>
                    <a
                        href={nextArticle.url}
                        aria-label="Next article"
                        className={styles.link}
                    >
                        <img
                            src={nextArticle.thumbnail}
                            alt=""
                            role="presentation"
                            className={styles.thumbnail}
                        />
                    </a>
                </div>
                <div className={styles.summary}>
                    <div className={styles['article-title']}>
                        <a href={nextArticle.url} className={styles.link}>
                            {parse(nextArticle.title)}
                        </a>
                    </div>
                    <a href={nextArticle.url} className={styles.link}>
                        <p className={styles.dek}>{parse(nextArticle.dek)}</p>
                    </a>
                </div>
            </div>
        </aside>
    );
}

export default NextInSeries;
