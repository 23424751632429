import { useRouter } from 'next/router';
import usePaywallStore from 'mfe-articles-shared/stores/paywall/paywall.store';
import useAuth from 'mfe-core/hooks/useAuth';
import useUser from 'mfe-core/hooks/useUser';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import ArticleService from 'mfe-articles-shared/services/ArticleService/ArticleService';
import DataLayer from 'mfe-articles-shared/domains/DataLayer/DataLayer';
import usePianoEvents from 'hooks/usePianoEvents/usePianoEvent';
import useArticleContentUpdates from 'hooks/useArticleContentUpdates/useArticleContentUpdates';
import Head from 'next/head';
import parse from 'html-react-parser';
import MetaTags from 'components/MetaTags/MetaTags';
import ExitPreviewLink from 'components/ExitPreviewLink/ExitPreviewLink';
import InterstitialModal from 'mfe-core/ui/InterstitialModal';
import LayoutResolver from 'components/LayoutResolver/LayoutResolver';
import PartnerCenter from 'mfe-core/ui/PartnerCenter';
import Footer from 'mfe-core/ui/Footer';
import PropTypes from 'prop-types';
import StructuredData from 'components/StructuredData/StructuredData';
import FontPreloader from 'mfe-core/ui/FontPreloader';

export default function ArticlePage({
    article,
    profile,
    initialDataLayer,
    pageURL,
}) {
    const { asPath, isPreview, query } = useRouter();
    const isPaywallEnabled = usePaywallStore((state) => state.isPaywallEnabled);
    const { setAuthCallback, setLogoutCallback } = useAuth();
    const { setProfile } = useUser();

    const setArticle = useArticleStore((state) => state.setArticle);
    const setTriggerPianoComposerCall = useArticleStore(
        (state) => state.setTriggerPianoComposerCall,
    );
    const setIsPaywallLoading = usePaywallStore(
        (state) => state.setIsPaywallLoading,
    );

    const fetchArticle = async () => {
        const { article: articleResponse } = await ArticleService.getArticle(
            asPath,
            null,
            query.language,
            {},
            query?.giftToken ? window?.location.href ?? null : null,
        );
        return articleResponse;
    };

    const updateArticleAndProfile = async () => {
        const articleResponse = await fetchArticle();
        setArticle(articleResponse);
        const { userProfile } = articleResponse;
        setProfile(userProfile);
        setIsPaywallLoading(true);

        // Trigger piano composer call to check for paywall
        setTriggerPianoComposerCall(true);
        return articleResponse;
    };

    const updateDataLayer = (articleResponse, userProfile) => {
        const dataLayer = new DataLayer();
        dataLayer.init({}, articleResponse, userProfile, query.language);
        dataLayer.updateUsers();
    };

    setAuthCallback(() => {
        updateArticleAndProfile();
    });

    setLogoutCallback(() => {
        const articleResponse = updateArticleAndProfile();
        updateDataLayer(articleResponse, articleResponse.userProfile);
    });

    usePianoEvents();
    useArticleContentUpdates();

    let pageTitle = article.title;
    if (article.alternateTitle) {
        pageTitle = article.alternateTitle;
    }

    return (
        <>
            <Head>
                <FontPreloader
                    variant={FontPreloader.variants.GT_AMERICA}
                    weight={FontPreloader.weights.BOLD}
                />
                <FontPreloader
                    variant={FontPreloader.variants.GT_AMERICA}
                    weight={FontPreloader.weights.EXTRA_LIGHT}
                />
                <FontPreloader
                    variant={FontPreloader.variants.GT_AMERICA}
                    weight={FontPreloader.weights.NORMAL}
                />
                <FontPreloader
                    variant={FontPreloader.variants.TIEMPOS_HEADLINE}
                    weight={FontPreloader.weights.SEMI_BOLD}
                />
                <FontPreloader
                    variant={FontPreloader.variants.TIEMPOS_TEXT}
                    weight={FontPreloader.weights.NORMAL}
                />
                <title>{parse(pageTitle)}</title>
                {article.hero && (
                    <link
                        rel="preload"
                        as="image"
                        href={article.hero.image.defaultSrc}
                        imageSrcSet={article.hero.image.srcset}
                        imageSizes="(min-width: 64em) 84vw, 100vw"
                    />
                )}
                <meta name="test" content="rollback" />
                <MetaTags profile={profile} />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.digitalData = ${JSON.stringify(initialDataLayer)}`,
                    }}
                />
            </Head>
            <StructuredData pageURL={pageURL} />
            {isPreview && <ExitPreviewLink />}
            {!article.configurations.configNoIntromercial && (
                <InterstitialModal />
            )}
            <LayoutResolver />
            {!isPaywallEnabled && (
                <>
                    <PartnerCenter />
                    <Footer />
                </>
            )}
        </>
    );
}

ArticlePage.propTypes = {
    profile: PropTypes.instanceOf(Object).isRequired,
    article: PropTypes.shape({
        title: PropTypes.string.isRequired,
        alternateTitle: PropTypes.string,
        configurations: PropTypes.shape({
            configNoIntromercial: PropTypes.bool,
        }),
        hero: PropTypes.shape({
            image: PropTypes.shape({
                defaultSrc: PropTypes.string,
                srcset: PropTypes.string,
                sizes: PropTypes.string,
            }),
        }),
    }).isRequired,
    initialDataLayer: PropTypes.instanceOf(Object).isRequired,
    pageURL: PropTypes.string.isRequired,
};
