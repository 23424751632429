import React, { useCallback, useMemo, useState, useEffect } from 'react';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import BrightcovePlayerLoader from '@brightcove/react-player-loader';
import usePaywallStore from '../../stores/paywall/paywall.store';
import VideoService from '../../services/VideoService/VideoService';
import PlayButtonVJSBridge from './PlayButton/PlayButtonVJSBridge';
import styles from './BrightcovePlayer.module.scss';
import VideoLoading from './VideoLoading/VideoLoading';
import DataLayer from '../../domains/DataLayer/DataLayer';

export default function BrightcovePlayer({ videoId, videoJSOptions }) {
    const isPaywallEnabled = usePaywallStore((state) => state.isPaywallEnabled);
    const isPaywallLoading = usePaywallStore((state) => state.isPaywallLoading);

    const [player, setPlayer] = useState(null);
    const options = useMemo(
        () => ({
            fluid: true,
            ...videoJSOptions,
        }),
        [videoJSOptions],
    );
    const successHandler = useCallback(({ ref: eventPlayer }) => {
        setPlayer(eventPlayer);
        eventPlayer.removeChild('BigPlayButton');
        eventPlayer.one('play', ({ target }) => {
            const controlBar = target.player.getChild('ControlBar');
            controlBar
                .getChild('SocialButton')
                ?.addClass(styles['control-bar-btn']);
            controlBar.show();
        });

        const controlBar = eventPlayer.getChild('ControlBar');
        controlBar.hide();
        controlBar
            .getChild('SubsCapsButton')
            ?.addClass(styles['control-bar-btn']);
        controlBar.getChild('PlayToggle')?.addClass(styles['control-bar-btn']);
        controlBar
            .getChild('VolumePanel')
            ?.getChild('MuteToggle')
            ?.addClass(styles['control-bar-btn']);
        controlBar
            .getChild('FullscreenToggle')
            ?.addClass(styles['control-bar-btn']);
        eventPlayer.addChild(new PlayButtonVJSBridge(eventPlayer));
    }, []);

    const { data: videoData } = useSWR(videoId, VideoService.getVideo);

    useEffect(() => {
        if (player && videoData) {
            const getVideoEvent = (eventName) => {
                const title = videoData.name;
                return new CustomEvent('video-interaction', {
                    detail: [title, eventName, window.parent],
                });
            };

            player.one('play', () => {
                window.dispatchEvent(getVideoEvent('Start'));
            });
            player.on('play', () => {
                const dataLayer = new DataLayer(window.digitalData);
                dataLayer.setVideoInteraction('Play', videoData.name);
                dataLayer.updateEvent();
                window.dispatchEvent(getVideoEvent('Play'));
            });
            player.on('pause', () => {
                const dataLayer = new DataLayer(window.digitalData);
                dataLayer.setVideoInteraction('Pause', videoData.name);
                dataLayer.updateEvent();
                window.dispatchEvent(getVideoEvent('Pause'));
            });
            player.on('ended', () => {
                const dataLayer = new DataLayer(window.digitalData);
                dataLayer.setVideoInteraction('Complete', videoData.name);
                dataLayer.updateEvent();
                window.dispatchEvent(getVideoEvent('Complete'));
            });
            player.on('volumechange', () => {
                window.dispatchEvent(getVideoEvent('Volume Change'));
            });
            player.on('fullscreenchange', () => {
                if (player.isFullscreen()) {
                    window.dispatchEvent(getVideoEvent('Fullscreen Enter'));
                } else {
                    window.dispatchEvent(getVideoEvent('Fullscreen Exit'));
                }
            });
            player.on('error', () => {
                window.dispatchEvent(getVideoEvent('Error'));
            });
            player.on('playing', () => {
                window.dispatchEvent(getVideoEvent('Play'));
            });
        }
    }, [player, videoData]);

    if (!isPaywallLoading && !isPaywallEnabled) {
        return (
            <BrightcovePlayerLoader
                accountId={VideoService.accountId}
                playerId="e17e6e1b-ffd1-4650-843d-76760bab288b"
                videoId={videoId}
                options={options}
                onSuccess={successHandler}
            />
        );
    }

    if (!isPaywallLoading && isPaywallEnabled && videoData) {
        return (
            <img
                src={videoData.poster}
                alt={videoData.long_description}
                role="presentation"
                className={styles.poster}
            />
        );
    }

    return <VideoLoading />;
}

BrightcovePlayer.defaultProps = {
    videoJSOptions: {},
};

BrightcovePlayer.propTypes = {
    videoId: PropTypes.string.isRequired,
    videoJSOptions: PropTypes.instanceOf(Object),
};
