import Anchor from 'mfe-core/ui/Anchor';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import PremiumHeadline from 'components/PremiumHeadline/PremiumHeadline';
import styles from './BigIdeaHeadline.module.scss';

function BigIdeaHeadline({ onHeader }) {
    const { series } = useArticleStore((state) => state.article);

    const containerClasses = classNames({
        [styles['headline-container-header']]: onHeader,
        [styles['headline-container']]: !onHeader,
    });

    return (
        <div className={containerClasses}>
            {series?.title ? (
                <>
                    <span>
                        <Anchor
                            href="/big-ideas"
                            className={styles['headline-title-anchor']}
                        >
                            The Big Idea Series
                        </Anchor>
                    </span>
                    <span className={styles['headline-separator']}>/</span>
                    <span className={styles['headline-series']}>
                        {parse(series.title)}
                    </span>
                </>
            ) : (
                <PremiumHeadline isVisible />
            )}
        </div>
    );
}

BigIdeaHeadline.defaultProps = {
    onHeader: false,
};

BigIdeaHeadline.propTypes = {
    onHeader: PropTypes.bool,
};

export default BigIdeaHeadline;
