import classNames from 'classnames';
import PropTypes from 'prop-types';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import parse from 'html-react-parser';
import PremiumHeadline from 'components/PremiumHeadline/PremiumHeadline';
import styles from './SpotlightHeadline.module.scss';

function SpotlightHeadline({ isVisible }) {
    const { series } = useArticleStore((state) => state.article);

    const containerClasses = classNames({
        [styles['content-container']]: true,
        [styles.visible]: isVisible,
    });

    return (
        <div className={containerClasses}>
            {series?.title ? (
                <>
                    <span className={styles['headline-title']}>
                        Spotlight Series
                    </span>
                    <span className={styles['headline-separator']}>/</span>
                    <span className={styles['headline-series']}>
                        {parse(series.title)}
                    </span>
                </>
            ) : (
                <PremiumHeadline isVisible={isVisible} />
            )}
        </div>
    );
}

SpotlightHeadline.propTypes = {
    isVisible: PropTypes.bool.isRequired,
};

export default SpotlightHeadline;
