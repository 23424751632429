import React from 'react';
import styles from './PlayButton.module.scss';
import playBtnIcon from '../../../assets/play-button.svg';

export default function PlayButton() {
    return (
        <div className={styles.container}>
            <img
                className={styles.icon}
                src={playBtnIcon.src}
                alt=""
                role="presentation"
            />
        </div>
    );
}
