import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import classNames from 'classnames';
import Anchor from 'mfe-core/ui/Anchor';
import styles from './MainTopicLink.module.scss';

function MainTopicLink() {
    const { topics, type, primaryTopic } = useArticleStore(
        (state) => state.article,
    );

    if (!topics?.[0]) {
        return null;
    }

    const articleType = type.replace(/\s+/g, '-');
    const topicHref = `/topic/subject/${topics[0]
        .toLowerCase()
        .replace(/\s+/g, '-')}`;

    const classes = classNames(styles.container, styles[articleType]);

    return (
        <div className={classes}>
            <Anchor className={styles.topic} href={topicHref}>
                {primaryTopic}
            </Anchor>
        </div>
    );
}

export default MainTopicLink;
