import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SeriesNavItem.module.scss';

const Variants = {
    TOP: 'top',
    BOTTOM: 'bottom',
};
function SeriesNavItem({
    position,
    currentIndex,
    url,
    index,
    thumbnail,
    title,
}) {
    const containerClasses = classNames(styles.item, {
        [styles.selected]: currentIndex === index,
        [styles[position]]: position === Variants.TOP,
    });

    return (
        <a href={url} className={containerClasses} title={title}>
            <div className={styles['item-index']}>{`0${index + 1}`}</div>
            {position === Variants.BOTTOM && (
                <div className={styles['item-image']}>
                    <img
                        className={styles['item-thumbnail']}
                        src={thumbnail}
                        alt=""
                        role="presentation"
                    />
                </div>
            )}
            <div className={styles['item-title']}>{title}</div>
        </a>
    );
}

SeriesNavItem.variants = Variants;

SeriesNavItem.defaultProps = {
    position: Variants.TOP,
};

SeriesNavItem.propTypes = {
    position: PropTypes.string,
    currentIndex: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    thumbnail: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default SeriesNavItem;
