import classNames from 'classnames';
import Anchor from 'mfe-core/ui/Anchor';
import PropTypes from 'prop-types';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import parse from 'html-react-parser';
import styles from './StandardHeadline.module.scss';

function StandardHeadline({ isVisible }) {
    const { topics, title } = useArticleStore((state) => state.article);

    const topicHref = `/topic/subject/${topics?.[0]
        .toLowerCase()
        .replace(/\s+/g, '-')}`;

    const containerClasses = classNames({
        [styles['content-container']]: true,
        [styles.visible]: isVisible,
    });

    return (
        <div className={containerClasses}>
            {topics?.[0] && (
                <>
                    <span className={styles['headline-topic']}>
                        <Anchor
                            className={styles['headline-topic-anchor']}
                            href={topicHref}
                        >
                            {topics[0]}
                        </Anchor>
                    </span>
                    <span className={styles['headline-separator']}>|</span>
                </>
            )}
            <span className={styles['headline-title']}>{parse(title)}</span>
        </div>
    );
}

StandardHeadline.propTypes = {
    isVisible: PropTypes.bool.isRequired,
};

export default StandardHeadline;
