import useFeatureFlags from 'mfe-core/hooks/useFeatureFlags';

function BinaryFlagComponent() {
    const { flags, getFlag } = useFeatureFlags();
    if (!flags) return null;
    const isBinaryFlagEnabled = getFlag('binary-flag');

    if (!isBinaryFlagEnabled) {
        return null;
    }

    return (
        <div>
            <p>Hello World! This is the Binary Flag feature.</p>
        </div>
    );
}

export default BinaryFlagComponent;
