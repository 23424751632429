import React from 'react';
import PropTypes from 'prop-types';
import styles from './Blockquote.module.scss';
import Paragraph from '../Paragraph/Paragraph';

function Blockquote({ children }) {
    return (
        <blockquote className={styles.blockquote}>
            <Paragraph>{children}</Paragraph>
        </blockquote>
    );
}

Blockquote.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Blockquote;
