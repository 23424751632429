/* eslint-disable react/jsx-props-no-spreading */
import React, { Children } from 'react';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import ContentLink from 'mfe-articles-shared/components/ContentLink/ContentLink';
import { getChildByTypeDeep } from 'react-nanny';
import StreamItem from '../../components/StreamItem/StreamItem';
import UnorderedList from '../../components/UnorderedList/UnorderedList';
import OrderedList from '../../components/OrderedList/OrderedList';
import ScriptResolver from '../../components/ScriptResolver/ScriptResolver';
import useResolverRegistryStore from '../../stores/resolverRegistry/resolverRegistry.store';
import HBRComponentResolver from '../../components/HBRComponentResolver/HBRComponentResolver';
import ParagraphResolver from '../../components/ParagraphResolver/ParagraphResolver';
import DivResolver from '../../components/DivResolver/DivResolver';
import Figure from '../../components/Figure/Figure';
import ArticlePromo from '../../components/ArticlePromo/ArticlePromo';
import articlePromoOptions from './articlePromoOptions/articlePromoOptions';
import ArticleSidebar from '../../components/ArticleSidebar/ArticleSidebar';
import IframeResolver from '../../components/IframeResolver/IframeResolver';
import ContentImage from '../../components/ContentImage/ContentImage';
import FigCaption from '../../components/FigCaption/FigCaption';
import SpanResolver from '../../components/SpanResolver/SpanResolver';
import SubheaderResolver from '../../components/SubheaderResolver/SubheaderResolver';
import NoopComponent from '../../components/NoopComponent/NoopComponent';
import EmbeddedVideo from '../../components/EmbeddedVideo/EmbeddedVideo';
import Table from '../../components/Table/Table';
import isTagNameValid from '../isTagNameValid/isTagNameValid';
import Blockquote from '../../components/Blockquote/Blockquote';

const setupParser = ({
    customResolvers = {},
    hbrComponentOverrides = {},
    paragraphOverrides = {},
    divOverrides = {},
    spanOverrides = {},
} = {}) => {
    const defaultResolverMap = {
        'hbr-component': HBRComponentResolver,
        'article-promo': ArticlePromo,
        'article-ideainbrief': NoopComponent,
        'article-sidebar': ArticleSidebar,
        'stream-item': StreamItem,
        'embedded-video': EmbeddedVideo,
        p: ParagraphResolver,
        a: ContentLink,
        h2: SubheaderResolver,
        h3: SubheaderResolver,
        h4: SubheaderResolver,
        h5: SubheaderResolver,
        h6: SubheaderResolver,
        ul: UnorderedList,
        ol: OrderedList,
        figure: Figure,
        figcaption: FigCaption,
        img: ContentImage,
        span: SpanResolver,
        div: DivResolver,
        iframe: IframeResolver,
        script: ScriptResolver,
        table: Table,
        blockquote: Blockquote,
    };

    let paragraphIndex = 0;
    let carouselIndex = 0;

    const resolverMap = { ...defaultResolverMap, ...customResolvers };

    useResolverRegistryStore.getState().reset();

    return (sourceHTML) => {
        const options = {
            replace: ({ name, attribs, children, parent }) => {
                if (name && !isTagNameValid(name)) return <NoopComponent />;

                let hasChildren = true;
                let hasOptions = true;
                let props = attributesToProps(attribs);
                let customOptions;

                if (name === 'article-promo') {
                    if (!customResolvers['article-promo']) {
                        customOptions = articlePromoOptions;
                    } else {
                        hasOptions = false;
                    }
                }

                if (name === 'img') {
                    hasChildren = false;
                }

                if (name === 'p') {
                    if (!parent) {
                        paragraphIndex += 1;
                        useResolverRegistryStore
                            .getState()
                            .register(name, paragraphIndex);
                    }
                    props = attributesToProps({
                        ...attribs,
                        index: !parent ? paragraphIndex : null,
                        overrides: paragraphOverrides,
                    });
                }

                if (name === 'hbr-component') {
                    hasChildren = false;
                    props = attributesToProps({
                        ...attribs,
                        overrides: hbrComponentOverrides,
                    });
                }

                if (name === 'div') {
                    props = attributesToProps({
                        ...attribs,
                        overrides: divOverrides,
                    });
                }

                if (name === 'span') {
                    props = attributesToProps({
                        ...attribs,
                        overrides: spanOverrides,
                    });
                }

                if (name === 'article-sidebar') {
                    const thumbnail = getChildByTypeDeep(
                        Children.toArray(domToReact(children)),
                        ['img'],
                    );

                    if (props?.className?.includes('sidebar--carousel')) {
                        useResolverRegistryStore
                            .getState()
                            .register('carousel', carouselIndex + 1);

                        carouselIndex += 1;
                    }

                    props = attributesToProps({
                        ...attribs,
                        thumbnail,
                        carouselSlot: carouselIndex,
                    });
                }

                if (['h2', 'h3', 'h4', 'h5', 'h6'].includes(name)) {
                    props = attributesToProps({
                        ...attribs,
                        name,
                    });
                }

                const ResolvedComponent = resolverMap[name] || null;

                if (!ResolvedComponent) return {};

                const childOptions = hasOptions ? customOptions || options : {};
                if (hasChildren) {
                    return (
                        <ResolvedComponent {...props}>
                            {domToReact(children, childOptions)}
                        </ResolvedComponent>
                    );
                }

                return (
                    <>
                        <ResolvedComponent {...props} />
                        {domToReact(children, childOptions)}
                    </>
                );
            },
        };

        return parse(sourceHTML, options);
    };
};

export default setupParser;
