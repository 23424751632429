import React from 'react';
import SeriesNavItem from 'components/SeriesNavItem/SeriesNavItem';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import styles from './TopSeriesNav.module.scss';

const SERIES_TITLE = {
    'big idea': 'The Big Idea Series',
    spotlight: 'Spotlight Series',
};

function TopSeriesNav() {
    const { series, type } = useArticleStore((state) => state.article);
    const href = {
        'big idea': '/big-ideas?ab=seriesnav-bigidea',
        spotlight: `${series?.articles?.[0].url}?ab=seriesnav-spotlight`,
    };

    if (!series) return null;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <a href={href[type]} className={styles.link}>
                    {SERIES_TITLE[type]}
                </a>
                <span className={styles['title-divider']}>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                </span>
                <span className={styles.title}>{series.title}</span>
            </div>
            <nav
                className={styles.content}
                aria-label="Article series navigation (top)"
            >
                {series.articles.map((item) => (
                    <SeriesNavItem
                        key={item.index}
                        currentIndex={series.currentIndex}
                        url={item.url}
                        index={item.index}
                        thumbnail={item.thumbnail}
                        title={item.title}
                        position={SeriesNavItem.variants.TOP}
                    />
                ))}
            </nav>
        </div>
    );
}

export default TopSeriesNav;
