import useFeatureFlags from 'mfe-core/hooks/useFeatureFlags';

function SegmentedFlagComponent() {
    const { flags, getFlag } = useFeatureFlags();

    if (!flags) return null;
    const segmentedFlagValue = getFlag('segmented-flag');

    if (!segmentedFlagValue) {
        return null;
    }

    switch (segmentedFlagValue) {
        case 'variant-a':
            return null;
        case 'variant-b':
            return (
                <div>
                    <p>This is Variant B of the segmented feature.</p>
                </div>
            );
        case 'variant-c':
            return (
                <div>
                    <p>This is Variant C of the segmented feature.</p>
                </div>
            );
        default:
            return null;
    }
}

export default SegmentedFlagComponent;
