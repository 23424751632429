import React from 'react';
import classNames from 'classnames';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import styles from './RelatedTopics.module.scss';
import { geographicalTopics, industryTopics } from './RelatedTopicLists';

function RelatedTopics() {
    const { topics, type } = useArticleStore((state) => state.article);

    if (!topics?.length) return null;
    const articleType = type.replace(/\s+/g, '-');

    const containerClasses = classNames(styles.container, styles[articleType]);

    const relatedTopicsContainerClasses = classNames(
        styles['related-topics'],
        styles[articleType],
    );

    const transformTopicToURL = (topic) => {
        let searchPrefix = 'subject';
        if (geographicalTopics.includes(topic)) {
            searchPrefix = 'geography';
        }
        if (industryTopics.includes(topic)) {
            searchPrefix = 'industry';
        }

        return `/topic/${searchPrefix}/${topic
            .toLowerCase()
            .replaceAll(' ', '-')
            .replaceAll('&', 'and')
            .replaceAll(',', '')
            .replace('randd', 'R.and.D')}?ab=articlepage-topic`; // special case for R&D topic
    };

    // primary category is the first topic
    const remainingTopics = topics.slice(1);

    let relatedTopicsText;
    if (remainingTopics.length === 1) {
        relatedTopicsText = ' or related topic ';
    } else if (remainingTopics.length > 1) {
        relatedTopicsText = ' or related topics ';
    } else {
        relatedTopicsText = '';
    }

    const getSeparator = (index, isLast) => {
        if (index === 0) return '';
        return isLast ? ' and ' : ', ';
    };

    return (
        <div className={containerClasses}>
            <span className={relatedTopicsContainerClasses}>
                Read more on{' '}
                <a
                    href={transformTopicToURL(topics[0])}
                    className={styles['topic-link']}
                >
                    <strong>{topics[0]}</strong>
                </a>
                {relatedTopicsText}
                {remainingTopics.map((topic, index) => {
                    const url = transformTopicToURL(topic);
                    const isLast = index === remainingTopics.length - 1;
                    return (
                        <React.Fragment key={topic}>
                            {getSeparator(index, isLast)}
                            <a href={url} className={styles['topic-link']}>
                                <strong>{topic}</strong>
                            </a>
                        </React.Fragment>
                    );
                })}
            </span>
        </div>
    );
}

export default RelatedTopics;
