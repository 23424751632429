import Script from 'next/script';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useArticle } from 'mfe-articles-renderer';
import getPlainText from 'mfe-articles-shared/utilities/getPlainText/getPlainText';

const authorHasRedFlags = (name) => {
    return name.match(/,|\(|\)|\.| and /gi);
};

const TestIds = {
    SCRIPT: 'script',
};

export default function StructuredData({ pageURL }) {
    const { article } = useArticle();
    let articleURL;

    try {
        articleURL = new URL(pageURL);
    } catch {
        articleURL = null;
    }

    const jsonString = useMemo(() => {
        const jsonObject = {
            '@context': 'https://schema.org',
            '@type': 'Article',
            ...(article.title && { headline: article.title }),
            ...(article.alternateTitle && {
                alternativeHeadline: article.alternateTitle,
            }),
            ...(article.dek && { description: getPlainText(article.dek) }),
            ...(article.summary && { abstract: getPlainText(article.summary) }),
            ...(article.hero?.image?.defaultSrc &&
                articleURL && {
                    image: `${articleURL.origin}${article.hero.image.defaultSrc}`,
                }),
            ...(article.published && { datePublished: article.published }),
            ...(article.metaTags?.['article:modified_time'] && {
                dateModified: article.metaTags['article:modified_time'],
            }),
            ...(articleURL && {
                url: `${articleURL.origin}${articleURL.pathname}`,
            }),
            isAccessibleForFree:
                !!article.configurations?.configExemptFromPaywall,
            publisher: {
                name: 'Harvard Business Review',
                ...(articleURL && { url: `${articleURL.origin}/` }),
            },
        };
        let addAuthors = true;
        const authorData = article.authors?.map((author) => {
            if (authorHasRedFlags(author.name)) {
                addAuthors = false;
            }

            return {
                '@type': 'Person',
                name: author.name,
                ...(author.xHandle && {
                    url: `https://x.com/${author.xHandle}`,
                }),
            };
        });

        if (addAuthors) {
            jsonObject.author = authorData;
        }

        return JSON.stringify(jsonObject);
    }, [article, articleURL]);

    return (
        <Script
            data-testid={TestIds.SCRIPT}
            id="structured-data"
            type="application/ld+json"
            strategy="beforeInteractive"
        >
            {jsonString}
        </Script>
    );
}

StructuredData.TestIds = TestIds;

StructuredData.propTypes = {
    pageURL: PropTypes.string.isRequired,
};
