import classNames from 'classnames';
import Anchor from 'mfe-core/ui/Anchor';
import PropTypes from 'prop-types';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import parse from 'html-react-parser';
import styles from './PremiumHeadline.module.scss';

const TestIds = {
    CONTAINER: 'premium-headline-container',
};

function PremiumHeadline({ isVisible }) {
    const { title, topics } = useArticleStore((state) => state.article);

    const topicHref = `/topic/subject/${topics?.[0]
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace('r&d', 'R.and.D')}`; // special case for r&d topic

    const containerClasses = classNames({
        [styles['content-container']]: true,
        [styles.visible]: isVisible,
    });

    return (
        <div className={containerClasses} data-testid={TestIds.CONTAINER}>
            {topics?.[0] && (
                <>
                    <span className={styles['headline-topic']}>
                        <Anchor
                            className={styles['headline-topic-anchor']}
                            href={topicHref}
                        >
                            {topics[0]}
                        </Anchor>
                    </span>
                    <span className={styles['headline-separator']}>|</span>
                </>
            )}
            <span className={styles['headline-title']}>{parse(title)}</span>
        </div>
    );
}

PremiumHeadline.propTypes = {
    isVisible: PropTypes.bool.isRequired,
};

export default PremiumHeadline;
export { TestIds as PremiumHeadlineTestIds };
