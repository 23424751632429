import { useEffect } from 'react';
import PropTypes from 'prop-types';
import usePaywallStore from 'mfe-articles-shared/stores/paywall/paywall.store';

function AssessmentHandler({ children }) {
    const { isPaywallEnabled, isPaywallLoading } = usePaywallStore((state) => ({
        isPaywallEnabled: state.isPaywallEnabled,
        isPaywallLoading: state.isPaywallLoading,
    }));

    const jquerySrc = 'https://code.jquery.com/jquery-3.7.1.min.js';

    useEffect(() => {
        if (isPaywallLoading || isPaywallEnabled) {
            return;
        }

        const loadForioScript = () => {
            const scriptElement = document.createElement('script');
            scriptElement.innerHTML = children;
            scriptElement.setAttribute('data-testid', 'forio');
            document.body.appendChild(scriptElement);
        };

        const loadJqueryScript = () => {
            const scriptElement = document.createElement('script');
            scriptElement.src = jquerySrc;
            document.body.appendChild(scriptElement);
        };

        const forioScriptElements = document.querySelectorAll(
            '[data-testid="forio"]',
        );

        if (!window.jQuery) loadJqueryScript();
        if (forioScriptElements.length === 0) loadForioScript();
    }, [children, isPaywallEnabled, isPaywallLoading]);

    return null;
}

AssessmentHandler.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AssessmentHandler;
