import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import classNames from 'classnames';
import BrightcovePlayer from 'mfe-articles-shared/components/BrightcovePlayer/BrightcovePlayer';
import BigIdeaHero from 'components/BigIdeaHero/BigIdeaHero';
import parse from 'html-react-parser';
import styles from './Hero.module.scss';

function Hero() {
    const { hero, heroResourceId, type, stylesOverride } = useArticleStore(
        (state) => state.article,
    );
    const articleType = type.replace(/\s+/g, '-');

    const containerClasses = classNames([styles[articleType]], {
        [styles['no-summary']]: !hero?.credits && !hero?.caption,
    });

    const captionAndCreditsClasses = classNames(
        styles['container-caption-credits'],
        styles[articleType],
    );

    return (
        (hero || heroResourceId) && (
            <div className={containerClasses}>
                {hero?.image &&
                    (stylesOverride?.some((className) =>
                        ['half-text-left', 'half-text-right'].includes(
                            className,
                        ),
                    ) ? (
                        <BigIdeaHero />
                    ) : (
                        <img
                            className={styles.img}
                            src={hero.image.defaultSrc}
                            sizes="(min-width: 64em) 84vw, 100vw"
                            srcSet={hero.image.srcset}
                            alt=""
                            role="presentation"
                        />
                    ))}
                {heroResourceId && (
                    <div className={styles['container-video']}>
                        <BrightcovePlayer videoId={heroResourceId} />
                    </div>
                )}
                {(hero?.credits || hero?.caption) && (
                    <div className={captionAndCreditsClasses}>
                        {hero.caption && (
                            <span className={styles.caption}>
                                {parse(hero.caption)} &nbsp;&nbsp;
                            </span>
                        )}
                        {hero.credits && (
                            <span className={styles.credits}>
                                {hero.credits}
                            </span>
                        )}
                    </div>
                )}
            </div>
        )
    );
}

export default Hero;
